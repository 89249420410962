/**
 * VoxImplant WebSDK docs
 * https://voximplant.com/docs/references/websdk
 */
import Vue from 'vue'
import {CallEvents, EndpointEvents, Events, getInstance, Hardware,} from 'voximplant-websdk'
import {userTypes} from '~/store/user'
import {CALL_STATUSES} from '~/store/voximplant'
import {attachmentProcessor} from "@/helpers/sentryAttachment"

const config = {
	enableTrace: false,//process.env.NODE_ENV !== 'production',
	showDebugInfo: false,//process.env.NODE_ENV !== 'production',
	prettyPrint: false,//process.env.NODE_ENV !== 'production',
	showWarnings: false,//process.env.NODE_ENV !== 'production',
	imAutoReconnect: true,
	imReconnectInterval: 1000,
	micRequired: true,
	remoteVideoContainerId: 'remote-video',
	localVideoContainerId: 'local-video',
	rtcStatsCollectionInterval: 5000
}

export default function ({store, $sentry, $devices}, inject) {
	const client = getInstance()

	let call = null
	let endpoint = null

	let timeoutError = false
	let unavailableError = false
	let noMediaDeviceError = false

	let reconnectTimer = null
	let recallTimer = null

	let changingDevices = false

	const intervalTimeForReconnect = 5000

	let sendVideo = true
	let isSendVideoMuting = false
	let sendVoice = true

	// logs
	const clientLogs = []
	client.setLoggerCallback((log) => {
		clientLogs.push(log);
	});

	/**
	 * Автодозвон
	 */
	function voxCallAttemptTimer(interval = 15000) {
		console.warn('>>> wait for recall')
		if (!recallTimer) {
			store.commit('voximplant/setRecallAttempts', true)
			console.warn('>>> start recall timer')
			recallTimer = setInterval(async () => {
				await startCall()
			}, interval)
		}
	}

	/**
	 * Остановить автодозвон
	 */
	function stopCallAttempt() {
		if (!recallTimer) return
		store.commit('voximplant/setRecallAttempts', false)
		console.warn('>>> stopCallAttempt')
		clearInterval(recallTimer)
		recallTimer = null
	}

	function voxOfflineHandler() {
		console.warn('>>> wait for reconnect')
		if (!reconnectTimer) {
			console.warn('>>> start interval timer')
			reconnectTimer = setInterval(async () => {
				await tryConnectAndLogin()
			}, intervalTimeForReconnect)
		}
	}

	function stopTryingReconnect() {
		console.warn('>>> stopTryingReconnect')
		clearInterval(reconnectTimer)
		reconnectTimer = null
	}

	async function tryConnect() {
		try {
			Vue.$toast('Идет подключение к серверу, это может занять несколько секунд, пожалуйста подождите', {type: 'warning', timeout: 15000});
			await connect();
			voxCallAttemptTimer();
		} catch (error) {
			if ($sentry) {
				$sentry.captureException(error)
			}
		}
	}

	async function loginWithOneTimeLoginKey() {
		const {userName, applicationName, account} = store.state.voximplant.currentVoxUser
		const URI = `${userName}@${applicationName}.${account}.voximplant.com`

		let error = null

		const req = await client
			.requestOneTimeLoginKey(URI)
			.then((e) => e)
			.catch((err) => {
				error = err;
				console.log('requestOneTimeLoginKey >>>', err )
			})

		if (req) {
			if (req.result) {
				console.log('vox user logged!', req.result ) // totally unreachable
			} else if (req.code === 302) {
				const key = req.key
				const requestToken = await store.dispatch(
					'voximplant/createVoxToken',
					key
				)
				const token = store.state.voximplant.currentVoxUser.token

				if (token) {
					await client
						.loginWithOneTimeKey(URI, token)
						.then(async (result) => {
							await store.commit('voximplant/setIsLogged', true)
						})
						.catch(async (err) => {
							console.log('loginWithOneTimeKey REQ', err )
							console.error(err)
							await store.commit('voximplant/setIsLogged', false)
							return err
						})
				} else {
					console.error(requestToken);
				}
			}
		}

		if (!req && error) {
			return error
		} else return req
	}

	async function configAndConnect() {
		try {
			await init(config)
			store.commit('voximplant/setConnectionClosed', false)
			initClientHandlers();
			initDevicesHandlers();
			await connect();
		} catch (error) {
			console.error('>>> config and connect', error)
			if ($sentry) {
				$sentry.captureException(error)
			}
		}
	}

	async function init(conf) {
		try {
			await client.init(conf)
			store.commit('voximplant/setIsInited', true)
		} catch (error) {
			store.commit('voximplant/setIsInited', false)
			console.error('>>> init', error)
			if ($sentry) {
				$sentry.captureException(error)
			}
		}
	}

	async function connect() {
		try {
			await client.connect()
			store.commit('voximplant/setIsConnected', true)
		} catch (error) {
			store.commit('voximplant/setIsConnected', false)
			console.error('>>> connect', error)
			if ($sentry) {
				$sentry.captureException(error)
			}
		}
	}

	async function disconnect() {
		try {
			await muteVideo(true);
			sendVideo = true;
			if (store.state.voximplant.isVoxConnected) {
				await client.disconnect()
			}
			store.commit('voximplant/setIsConnected', false)
			store.commit('voximplant/setIsLogged', false)
			navigator.mediaDevices.removeEventListener('devicechange', deviceChangedHandler)
			call = null

			$devices.disconnect();
		} catch (error) {
			console.error('>>> disconnected', error)
			if ($sentry) {
				$sentry.captureException(error)
			}
		}
	}

	// DEVICES
	async function selectInputDevice(deviceId) {
		if (deviceId) {
			try {
				const manager = Hardware.AudioDeviceManager.get()

				if (call) {
					const settings = manager.getCallAudioSettings(call)
					const oldId =
						settings && settings.inputId ? settings.inputId : null
					if (!oldId || oldId !== deviceId) {
						await manager.setCallAudioSettings(call, {
							...settings,
							inputId: deviceId,
						})
					}
				} else {
					const defaultSettings = manager.getDefaultAudioSettings()
					manager.setDefaultAudioSettings({
						...defaultSettings,
						inputId: deviceId,
					})
				}

				store.commit('voximplant/setCurrentDevice', {type: 'input', device: {id: deviceId}})
				await $devices.updateInputStream(deviceId)
			} catch (error) {
				console.error('>>> selectInputDevice ', error)
				if ($sentry) {
					$sentry.captureException(error)
				}
			}
		}
	}

	async function selectOutputDevice(deviceId) {
		if (deviceId) {
			try {
				const manager = Hardware.AudioDeviceManager.get()

				if (call) {
					const settings = manager.getCallAudioSettings(call)
					const oldId =
						settings && settings.outputId ? settings.outputId : null
					if (!oldId || oldId !== deviceId) {
						await manager.setCallAudioSettings(call, {
							...settings,
							outputId: deviceId,
						})
					}
				} else {
					const defaultSettings = manager.getDefaultAudioSettings()
					manager.setDefaultAudioSettings({
						...defaultSettings,
						outputId: deviceId,
					})
				}

				store.commit('voximplant/setCurrentDevice', {type: 'output', device: {id: deviceId}})
				$devices.updateAudioElement(deviceId)
			} catch (error) {
				console.error('>>> selectOutputDevice ', error)
				if ($sentry) {
					$sentry.captureException(error)
				}
			}
		}
	}

	async function selectCameraDevice(deviceId) {
		if (deviceId) {
			try {
				const manager = Hardware.CameraManager.get()
				if (call && call.settings.state === 'CONNECTED') {
					const settings = manager.getCallVideoSettings(call)
					const oldId = settings && settings.cameraId ? settings.cameraId : null
					if (!oldId || oldId !== deviceId) {
						await manager.setCallVideoSettings(call, {
							...settings,
							cameraId: deviceId,
						})
					}
				} else {
					const defaultSettings = manager.getDefaultVideoSettings()
					await manager.setDefaultVideoSettings({
						...defaultSettings,
						cameraId: deviceId,
					})
				}

				store.commit('voximplant/setCurrentDevice', {type: 'camera', device: {id: deviceId}})
			} catch (error) {
				console.error('>>> selectCameraDevice', error)
				if ($sentry) {
					$sentry.captureException(error)
				}
			}
		}
	}

	async function deviceChangedHandler(changeCounter = true) {
		if (changingDevices) {
			console.warn('>>> deviceChangedHandler SKIP')
			return
		}
		changingDevices = true;

		const manager = Hardware.AudioDeviceManager.get();

		try {
			const newDeviceInput = $devices.audioInputChangeHandler();

			if (call) {
				const settings = manager.getCallAudioSettings(call)
				if (newDeviceInput /* && settings.inputId !== newDeviceInput.id */) {
					await manager.setCallAudioSettings(call, {
						...settings,
						inputId: newDeviceInput.id,
					})
				}
			}
		} catch (error) {
			if ($sentry) {
				$sentry.captureException(error)
			}
			console.error('>>> deviceChangedHandler audioInputDevices error', error)
		}

		try {
			const newDeviceOutput = $devices.audioOutputChangeHandler();

			if (call) {
				const settings = await manager.getCallAudioSettings(call)

				if (newDeviceOutput /* && settings.outputId !== newDeviceOutput.id */) {

					await manager.setCallAudioSettings(call, {
						...settings,
						outputId: newDeviceOutput.id,
					})
				}
			}
		} catch (error) {
			if ($sentry) {
				$sentry.captureException(error)
			}
			console.error('>>> deviceChangedHandler audioOutputDevices error', error)
		}

		try {
			const cameraManager = Hardware.CameraManager.get()
			const newCamera = $devices.cameraChangeHandler();
			let switchCameraBack = false

			if (call) {
				const settings = cameraManager.getCallVideoSettings(call)

				if (newCamera /* && settings.cameraId !== newCamera.id */) {
					await cameraManager.setCallVideoSettings(call, {
						...settings,
						cameraId: newCamera.id,
					})

					if (sendVideo) {
						await client.showLocalVideo(!sendVideo)
						switchCameraBack = true
					}
				}
			}

			if (sendVideo && (switchCameraBack || !changeCounter)) {
				console.log('client.showLocalVideo >>> ', sendVideo);
				await client.showLocalVideo(sendVideo)
			}

		} catch (error) {
			if ($sentry) {
				$sentry.captureException(error)
			}
			console.error('>>> deviceChangedHandler cameraDevices error', error)
		}

		changingDevices = false
		return true
	}

	function initDevicesHandlers() {
		console.warn('>>> initDevicesHandlers')
		navigator.mediaDevices.addEventListener('devicechange', deviceChangedHandler)
	}

	// EVENTS LISTENERS
	function initClientHandlers() {
		client.on(Events.PlaybackError, (ev) => {
			console.warn('>>> Events.PlaybackError')
			const playButton = document.getElementById('playVideoManual')
			playButton.onclick = () => ev.element.play()
			playButton.click()
		})

		client.on(Events.MicAccessResult, (ev) => {
			if (ev.result) {
				console.warn('mic access granted')
				store.commit('voximplant/setMicAccessGranted', true)
				store.commit('voximplant/setDevicesError', false)
			} else {
				console.warn('mic access declined')
				console.warn(ev)
				store.commit('voximplant/setMicAccessGranted', false)
				store.commit('voximplant/setDevicesError', true)
			}
		})

		client.on(Events.Reconnecting, (ev) => {
			console.warn('>>> Reconnecting', ev)
			store.commit('voximplant/setIsReconnecting', true)
		})

		client.on(Events.Reconnected, (ev) => {
			console.warn('>>> Reconnected', ev)
			store.commit('voximplant/setIsReconnecting', false)
			store.commit('voximplant/setConnectionClosed', false)
			stopTryingReconnect()
		})

		client.on(Events.ConnectionClosed, (ev) => {
			console.warn('>>> ConnectionClosed', ev)
			store.commit('voximplant/setConnectionClosed', true)
		})

		client.on(Events.ConnectionFailed, (ev) => {
			console.warn('>>> ConnectionFailed', ev)
		})

		client.on(Events.ConnectionEstablished, async (ev) => {
			console.warn('>>> ConnectionEstablished', ev);
			store.commit('voximplant/setIsReconnecting', false);
			store.commit('voximplant/setConnectionClosed', false);
			deviceChangedHandler(false);

			// if(!store.state.voximplant.isVoxLogged) {
				await loginWithOneTimeLoginKey();
			// }
		})
	}

	let prevReceived = 0;
	let prevLost = 0;

	function initCallHandlers(call) {
		prevReceived = 0;
		prevLost = 0;

		console.log('>>> initCallHandlers for ', call)

		call.on(CallEvents.Failed, async (event) => {
			console.warn('>>> CallEvents.Failed:')
			console.warn(event, recallTimer)

			try {
				if(event.reason && (event.reason.toString().includes('1003') || event.reason.toString().includes('1004'))) {
					await tryConnect();
				} else {
					Vue.$toast(`Код ошибки : ${event.reason}`, {type: 'error'});
				}

			} catch (e) {
				reportLogs(`Код ошибки : ${event.reason}`);
				console.log(e);

				if ($sentry) {
					$sentry.captureException(e)
				}
			}

			stopCallAttempt()
			const code = event.code
			store.commit('voximplant/updateCall', {error: code})
			if (code === 408) {
				timeoutError = true
				unavailableError = false
				noMediaDeviceError = false
			}
			if (code === 480) {
				timeoutError = false
				unavailableError = true
				noMediaDeviceError = false
			}
			if (code === 403) {
				timeoutError = false
				unavailableError = false
				noMediaDeviceError = true
			}
			if (code === 603) {
				timeoutError = false
				unavailableError = false
				noMediaDeviceError = false
			}

			try {
				const sessionId = store.getters['sessions/getCurrentSessionId'];
				const formData = new FormData();
				formData.append('event_type', 'session.connection.failed');
				formData.append('voximplant_logs', JSON.stringify(clientLogs));

				if (store.state.user.userType === userTypes.client) {
					store.dispatch('sessions/sendSessionLogs', {
						session_id: sessionId,
						userType: 'client',
						data: formData
					});
				} else {
					store.dispatch('sessions/sendSessionLogs', {
						session_id: sessionId,
						userType: 'psychologist',
						data: formData
					});
				}
			} catch (e) {
				console.log(e);

				if ($sentry) {
					$sentry.captureException(e)
				}
			}
		})

		call.on(CallEvents.Disconnected, (event) => {
			stopCallAttempt()
			console.warn('>>> CallEvents.Disconnected:', event);
		})

		call.on(CallEvents.StateUpdated, (event) => {
			console.warn('>>> CallEvents.StateUpdated:', event)
			if (event.new === CALL_STATUSES.CONNECTED) {
				if (!sendVoice) {
					call.muteMicrophone()
				}
				sendIsMute(!sendVoice)
			}
			if (event.old === CALL_STATUSES.CONNECTED && event.new === CALL_STATUSES.CONNECTED) {
				if (event.call.settings.videoDirections.sendVideo !== sendVideo) {
					muteVideo(!sendVideo)
				}
			}
			stopCallAttempt()
			store.commit('voximplant/setCallStatus', event.new)
			if (timeoutError && event.new === CALL_STATUSES.ENDED) {
				console.warn('>>> Retry Call')
				startCall()
			} else if (unavailableError && event.new === CALL_STATUSES.ENDED) {
				console.warn('>>> Psy not ready')
				voxCallAttemptTimer()
			} else if (
				noMediaDeviceError &&
				event.new === CALL_STATUSES.ENDED
			) {
				console.warn('>>> No media device')
			} else if (event.new === CALL_STATUSES.ENDED) {
				console.warn('>>> Call ended')
				call = null
			}
		})

		call.on(CallEvents.CallStatsReceived, (event) => {
			console.warn('>>> CallEvents.CallStatsReceived', event)

			const {inbound} = event.stats;
			let lost = 0;
			let received = 0;
			Object.values(inbound).forEach(stream => {
				lost += stream.packetsLost;
				received += stream.packetsReceived;
			})

			lost -= prevLost;
			received -= prevReceived;

			prevLost += lost;
			prevReceived += received;

			if (received) {
				const totalLoss = ((received - lost) / -received) + 1;
				document.getElementById('connection-quality').classList.remove('red');
				document.getElementById('connection-quality').classList.remove('yellow');
				if (totalLoss > .05) {
					document.getElementById('connection-quality').classList.add(totalLoss > .15 ? 'red' : 'yellow');
				}
			}

			let jitter = 0;
			Object.values(inbound).forEach(stream => {
				if (stream.jitter > jitter) {
					jitter = stream.jitter;
				}
			})
			document.getElementById('jitter-quality').classList.remove('show');
			if (jitter > 0.3) {
				document.getElementById('jitter-quality').classList.add('show');
			}
		})

		call.on(CallEvents.MessageReceived, (e) => {
			const message = JSON.parse(e.text);

			if (message.type === 'mute') {
				const {muted} = message.params;
				store.commit('voximplant/updateCall', {interlocutorMuted: muted})
			}
		})

		call.addEventListener(CallEvents.Connected, (e) => {
			e.call.on(CallEvents.EndpointAdded, (e) => {
				if (!document.getElementById(store.state.voximplant.currentVoxUser.userName)) {
					const container = document.createElement('div');
					container.id = store.state.voximplant.currentVoxUser.userName;
					container.style.width = '100%';
					document.getElementById("remote-video").appendChild(container)
				}

				store.commit('voximplant/setIsEndpointAdded', true)

				e.endpoint.on(EndpointEvents.RemoteMediaAdded, (e) => {
					const container = document.getElementById(store.state.voximplant.currentVoxUser.userName);
					e.mediaRenderer.render(container);
				});
			});
			e.call.on(CallEvents.EndpointRemoved, (e) => {
				store.commit('voximplant/setIsEndpointAdded', false);
			});
		});
	}

	// FOR CONTROLS
	async function startCall(customId = '') {
		console.warn('START CALL >>>');
		timeoutError = false
		call = null;

		try {
			const sessionId = store.getters['sessions/getCurrentSessionId'];
			const session = store.getters['sessions/getCurrentSessionData'];
			const number = `sessionID-${sessionId.replaceAll('-', '')}`;

			if (client.getClientState() === 'DISCONNECTED') {
				await tryConnect();
				return;
			}

			const customData = {
				underControl: session?.do_recording,
				recordName: `SESSION_${sessionId.replaceAll('-', '')}_${Date.now()}`
			};

			console.log('CustomData', customData);

			call = client.callConference({
				number: number,
				simulcast: true,
				customData: JSON.stringify(customData),
				video: {
					sendVideo: true,
					receiveVideo: true,
				},
			});

			store.commit('voximplant/setCallStatus', call.settings.state)
			initCallHandlers(call);

			clientLogs.push({
				formattedText: 'Состояние флага записи звонка',
				category: 3,
				label: 'customData',
				level: 3,
				message: JSON.stringify({
					customData,
					session
				})
			});

			const formData = new FormData();
			formData.append('event_type', 'session.connection.success');
			formData.append('voximplant_logs', JSON.stringify(clientLogs));

			if (store.state.user.userType === userTypes.client) {
				await store.dispatch('sessions/sendSessionLogs', {
					session_id: sessionId,
					userType: 'client',
					data: formData
				});
			} else {
				await store.dispatch('sessions/sendSessionLogs', {
					session_id: sessionId,
					userType: 'psychologist',
					data: formData
				});
			}

		} catch (error) {
			console.warn('>>> call create error', error)
			if ($sentry) {
				$sentry.captureException(error)
			}

			const sessionId = store.getters['sessions/getCurrentSessionId'];
			const formData = new FormData();
			formData.append('event_type', 'session.connection.failed');
			formData.append('voximplant_logs', JSON.stringify(clientLogs));

			if (store.state.user.userType === userTypes.client) {
				await store.dispatch('sessions/sendSessionLogs', {
					session_id: sessionId,
					userType: 'client',
					data: formData
				});
			} else {
				await store.dispatch('sessions/sendSessionLogs', {
					session_id: sessionId,
					userType: 'psychologist',
					data: formData
				});
			}
		}
	}

	async function hangup() {
		try {
			call.hangup();
			const sessionId = store.getters['sessions/getCurrentSessionId'];
			const formData = new FormData();
			formData.append('event_type', 'session.connection.terminated');

			if (store.state.user.userType === userTypes.client) {
				await store.dispatch('sessions/sendSessionLogs', {
					session_id: sessionId,
					userType: 'client',
					data: formData
				});
			} else {
				await store.dispatch('sessions/sendSessionLogs', {
					session_id: sessionId,
					userType: 'psychologist',
					data: formData
				});
			}

		} catch (error) {
			console.error(`hangup`, error)
			if ($sentry) {
				$sentry.captureException(error)
			}
		}
	}

	function sendIsMute(isMute) {
		call.sendMessage(JSON.stringify({
			type: "mute",
			params: {
				muted: isMute
			}
		}));
	}

	function muteMic(isMute) {
		sendVoice = !isMute;
		try {
			if (call && call.settings.state === 'CONNECTED') {
				if (isMute) {
					call.muteMicrophone()
					console.warn('>>> mute mic')
				} else {
					call.unmuteMicrophone()
					console.warn('>>> unmute mic')
				}
				sendIsMute(isMute)
			}
		} catch (error) {
			console.error(`muteMic`, error)
			if ($sentry) {
				$sentry.captureException(error)
			}
		}
	}

	async function muteVideo(isMute) {
		if (!isSendVideoMuting) {
			try {
				isSendVideoMuting = true
				console.warn('showLocalVideo', !isMute)
				sendVideo = !isMute
				await client.showLocalVideo(!isMute);
				if (call && call.settings.state === 'CONNECTED') {
					await call.sendVideo(!isMute)
				}
				isSendVideoMuting = false
			} catch (error) {
				console.error('muteVideo', error)
				isSendVideoMuting = false
				if ($sentry) {
					$sentry.captureException(error)
				}
			}
		}
	}

	function shareScreen() {
		try {
			call.shareScreen().then(() => {
				store.commit('voximplant/setIsScreenSharing', true)
			})
		} catch (error) {
			console.error(`shareScreen`, error)
			if ($sentry) {
				$sentry.captureException(error)
			}
		}
	}

	function stopSharingScreen() {
		try {
			call.stopSharingScreen()
			store.commit('voximplant/setIsScreenSharing', false)
		} catch (error) {
			console.error(`stopSharingScreen`, error)
			if ($sentry) {
				$sentry.captureException(error)
			}
		}
	}

	function reportLogs(message) {
		const logs = clientLogs;
		if ($sentry) {
			console.error('>>> ReportLogs', message, logs.length)

			// $sentry.captureException(new Error("Exception before Report Logs"));
			// $sentry.captureMessage('My Message');

			$sentry.withScope(function (scope) {
				scope.addEventProcessor(function (event, hint) {
					const hub = $sentry.getCurrentHub()
					const client = hub.getClient()
					const dsn = client.getDsn()
					attachmentProcessor(dsn, event, {message, logs})
					console.info('>>> addEventProcessor', client, dsn)
					return event;
				});
				$sentry.captureException(new Error("Report Logs"))
			});
		}
	}

	const vox = {
		client,
		endpoint,
		configAndConnect,
		connect,
		disconnect,
		login: loginWithOneTimeLoginKey,
		startCall,
		hangup,
		muteMic,
		muteVideo,
		voxOfflineHandler,
		stopCallAttempt,
		stopTryingReconnect,
		selectInputDevice,
		selectOutputDevice,
		selectCameraDevice,
		deviceChangedHandler,
		shareScreen,
		stopSharingScreen,
		reportLogs,
	}

	inject('vox', {...vox})

	return {...vox}
}
